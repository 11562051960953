<template>
  <div>
    <template>
      <div>
        <ScrollupSection />
        <div class="main overflow-hidden">
          <HeaderSection />
          <BlogThreeColumn :titulo="docData.titulo" :descripcion="docData.descripcion" :background="docData.image"/>
            <section class="section blog-area ptb_100">
                <div class="container">
                <h4>Contenido</h4>
                <p>
                </p>
                <hr>
                <div v-if="docData.pdf">
                    <h4>{{currentPage}} / {{pageCount}}</h4>
                    <pdf :src="docData.pdf"
                        @num-pages="pageCount = $event"
                        @page-loaded="currentPage = $event"
                        v-for="i in numPages"
                        :key="i"
                        :page="i"
                    ></pdf>
                </div>
                </div>
            </section>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import BlogThreeColumn from "../components/Breadcrumb/BlogThreeColumn";

import { fr , analytics } from "../firebase";
import pdf from 'vue-pdf';


export default {
  name: "BlogPost",
  components: {
    ScrollupSection,
    HeaderSection,
    BlogThreeColumn,
    pdf,
  },
  data () {
    return {
      docData: '',
      numPages: 0,
      currentPage: 0,
      pageCount: 0,
    }
  },
  methods: {
      async ObtenerDatos(){
        const id = this.$route.query.blog_id;
        const documentsRef = fr.collection('blog');
        const snapshot = await documentsRef.doc(id).get();
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        if (snapshot.empty) {
            console.log('No matching documents.');
            return;
        }else{
            this.docData = snapshot.data();
            analytics.logEvent("Consulta-BlogLink",{
              event_title: this.docData.titulo,
              event_time: dateTime,
              event_label: dateTime,
              event_category: this.docData.titulo,
            });
            // IF THE DOCUMENT IS A PDF
            console.log(this.docData);
            const loadingTask  = pdf.createLoadingTask( this.docData.pdf );
            loadingTask.promise.then( pdf => {
                this.numPages = pdf.numPages; 
            });
                        //
        }
      }
  },
  mounted(){
      this.ObtenerDatos();
  }
}
</script>