<template>
  <div>
    <template>
      <div>
        <ScrollupSection />
        <div class="main overflow-hidden">
          <HeaderSection />
          <PerfilBreadcrumb :titulo="titulo" :descripcion="descripcion" />
            <section class="section blog-area ptb_100">
                <div class="container">
                <h4> Contenido   </h4>
                <p>
                </p>
                <hr>
                <div v-if="docData.url_doc">
                    <h4>{{currentPage}} / {{pageCount}}</h4>
                    <pdf :src="docData.url_doc"
                        @num-pages="pageCount = $event"
                        @page-loaded="currentPage = $event"
                        v-for="i in numPages"
                        :key="i"
                        :page="i"
                    ></pdf>
                </div>
                </div>
            </section>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ScrollupSection from "../components/Scrollup/Scrollup";
import HeaderSection from "../components/Header/HeaderOne";
import PerfilBreadcrumb from "../components/Breadcrumb/PerfilBreadcrumb";

import { fr , analytics } from "../firebase";
import pdf from 'vue-pdf';


export default {
  name: "Documents",
  components: {
    ScrollupSection,
    HeaderSection,
    PerfilBreadcrumb,
    pdf,
  },
  data () {
    return {
      titulo: 'Documentos relevantes',
      descripcion: '',
      docData: '',
      numPages: 0,
      currentPage: 0,
      pageCount: 0,
    }
  },
  methods: {
      async ObtenerDatos(){
        const id = this.$route.query.document_id;
        const documentsRef = fr.collection('documentosRef');
        const snapshot = await documentsRef.doc(id).get();

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        if (snapshot.empty) {
            console.log('No matching documents.');
            return;
        }else{
            this.docData = snapshot.data();
            analytics.logEvent("Consulta-DocAndFiles",{
              event_title: this.docData.titulo_doc,
              event_time: dateTime,
              event_label: dateTime,
              event_category: this.docData.titulo_doc,
            });
            // IF THE DOCUMENT IS A PDF
            if(this.docData.type == "pdf"){
                if(this.docData.boletin == 1){
                  this.titulo = "Boletín ElectoralTech"
                } else { this.titulo = this.docData.titulo_doc; }
                this.descripcion = 'Documentos relevantes';
                
                const loadingTask  = pdf.createLoadingTask( this.docData.url_doc );
                loadingTask.promise.then( pdf => {
                    this.numPages = pdf.numPages; 
                });
            }            //
        }
      }
  },
  mounted(){
      this.ObtenerDatos();
  }
}
</script>